<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Statuses">
                    <template v-slot:description>
                        <div>
                            <div>import { HbStatus } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Basic Status Example + Code" class="mt-4 mb-6">
            <div class="pa-6">
                <HbStatus status="Current" />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbStatus status="Current" />
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Tenant Statuses" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="[
                    { color: '#02AD0F', name: 'Current', description: 'Tenant in good standing'},
                    { color: '#FFD600', name: 'Pending', description: 'Active Lead has paid but not signed the Agreement'},
                    { color: '#FFD600', name: 'Delinquent', description: 'Tenant that is 1+ Days delinquent'},
                    { color: '#FFD600', name: 'Suspended', description: 'Rent, Fee or Auction/Lien Process Suspension, Pending Verified Move-Out'},
                    { color: '#FB4C4C', name: 'Active Lien', description: 'Tenant has been sent an Pre-Lien or Lien Letter'},
                    { color: '#FB4C4C', name: 'Bankruptcy', description: 'Tenant has filed for Bankruptcy (not a status used currently on HB)'},
                    { color: '#FB4C4C', name: 'Auction', description: 'Tenant Scheduled in Auction provides an Auction date'},
                    { color: '#919EAB', name: 'Lease Closed', description: 'Tenant whose space has been moved out would display a Lease Closed status'},
                ]"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatus :status="item.name" />
                </template>
                
                <template v-slot:item.description="{ item }">
                    {{ item.description }}
                </template>
                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatus status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Space Statuses" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="[
                    { color: '#02AD0F', name: 'Available', description: 'In Inventory, ready to lease'},
                    { color: '#02AD0F', name: 'Current', description: 'Space is in inventory and ready to be leased'},
                    { color: '#FFD600', name: 'On-Hold', description: 'When the space is rented from Mariposa, the space goes into an On-Hold status for 15 mins'},
                    { color: '#FFD600', name: 'Reserved', description: 'The space is tied to an active lead and is removed from available inventory'},
                    { color: '#FFD600', name: 'Pending', description: 'When a move-in from Hummingbird is discontinued and the move-in is saved to be proceeded at a later time, the space for which the move-in was discontinued would display Pending status'},
                    { color: '#FFD600', name: 'Future Leased', description: 'When a space is rented for a future move-in date, it would display a status Future Leased'},
                    { color: '#919EAB', name: 'Offline', description: 'Space is scheduled for maintenance'},
                    { color: '#919EAB', name: 'Deactivated', description: 'When a space is deactivated through Space Management'},
                    { color: '#919EAB', name: 'Deleted', description: 'When a space is deleted through Space Management'},
                    { color: '#FB4C4C', name: 'Remove Overlock', description: 'Previously delinquent Tenant is now Current requiring either a manual or electronic removal of restrictions'},
                    { color: '#FB4C4C', name: 'To Overlock', description: 'There is an active task for the manager to place an overlock on a space'},
                    { color: '#FB4C4C', name: 'Overlocked', description: 'Space has manually or electronically restricted tenant access'},
                    { color: '#FB4C4C', name: 'Schedule for Auction', description: 'Space is ready to be scheduled for auction'},
                    { color: '#3D8FFF', name: 'Charity', description: 'Space donated for charitable purpose'},
                    { color: '#3D8FFF', name: 'Company', description: 'Space occupied by the company for business purposes'},
                    { color: '#3D8FFF', name: 'Leased', description: 'Space is occupied by a tenant'},
                ]"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatus :status="item.name" />
                </template>
                
                <template v-slot:item.description="{ item }">
                    {{ item.description }}
                </template>
                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatus status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Lead Statuses" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="[
                    { color: '#02AD0F', name: 'Converted', description: 'When a lead is converted to a tenant'},
                    { color: '#FFD600', name: 'Active', description: 'Tenant that is digitally or manually nurtured'},
                    { color: '#919EAB', name: 'Retired', description: 'Digitally or manually exhausted'},
                ]"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatus :status="item.name" />
                </template>
                
                <template v-slot:item.description="{ item }">
                    {{ item.description }}
                </template>
                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatus status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <!--
        <hb-card title="Invoice Statuses" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="[
                    { color: '#02AD0F', name: 'Paid', description: 'The state that displays an invoice has been fully paid'},
                    { color: '#FFD600', name: 'Open', description: 'When an invoice that is not due and has some pending charges'},
                    { color: '#919EAB', name: 'Void', description: 'When an invoice is voided'},
                    { color: '#919EAB', name: 'Write Off', description: 'When an invoice is written off as a loss'},
                    { color: '#FB4C4C', name: 'Past Due', description: 'When an invoice is due or past due and has incomplete or no payments associated with it'},
                ]"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <hb-status>{{ item.name }}</hb-status>
                </template>
                
                <template v-slot:item.description="{ item }">
                    {{ item.description }}
                </template>
                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;hb-status>{{ item.name }}&lt;/hb-status></div>
                </template>
            </hb-data-table>
        </hb-card>

       <hb-card title="Document Statuses" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="[
                    { color: '#02AD0F', name: 'Signed', description: 'When a document is completely executed'},
                    { color: '#FFD600', name: 'Signing in Progress', description: 'When the document signing is in progress'},
                    { color: '#FFD600', name: 'Ready to Sign', description: 'When a document is generated and ready to be signed'},
                    { color: '#3D8FFF', name: 'Generated', description: 'When a document that has no signature is generated'},
                    { color: '#3D8FFF', name: 'Uploaded', description: 'When a document is uploaded'},
                ]"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <hb-status>{{ item.name }}</hb-status>
                </template>
                
                <template v-slot:item.description="{ item }">
                    {{ item.description }}
                </template>
                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;hb-status>{{ item.name }}&lt;/hb-status></div>
                </template>
            </hb-data-table>
        </hb-card>
        -->

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="snackbarItem + ' <hb-status> component code copied to clipboard.'"
        >
        </hb-global-notification>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemStatuses",
        data: function() {
            return {
                snackbarItem: '',
                success: false,
                headers: [
                    { text: 'Chip', value: 'chip' },
                    { text: 'Definition', value: 'description' },
                    { text: 'Grab Code', value: 'code' }
                ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'status', type: 'string', default: 'undefined', description: 'Sets the status text. Aviary component will also set the color based upon a text match. If there is no match, the color will be default gray-ish color.' },
                    { name: 'display-text', type: 'string', default: 'undefined', description: 'Overrides the text displayed for the status. Useful if you want to keep the same "status" prop and color but just want to change the text displayed. For example, for Hummingbird AU legal/localization, we still are using "Leased" status sent from the backend and for app architecture, but AU needs to show the text as "Rented". So setting "status" as "Leased" and "display-text" as "Rented" would be the fix for this use case. ' },
                    { name: 'color', type: 'string', default: 'undefined', description: 'Manually changes the color of the status chip. Most of the time, this prop is not necessary, as the color of the status is hardcoded to the value of the text. You can set this prop to a hex code value or you can set it to "succes", "caution", "error", "guidance", or "default".' },
                    { name: 'pointer', type: 'boolean', default: 'undefined', description: 'If changed to true, this will apply "cursor: pointer" css style when chip is hovered over.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@emitColor', description: 'Event for when color is set/updated/changed, the data sent includes the hex code value of the color of the status. Useful when you want an outside element to color sync with the status color.' },
                    { name: '@emitType', description: 'Event for when color matches one of the preset types and is set/updated/changed, the possible types sent are "success", "caution", "error", "guidance", and "default". Useful when you want an outside element to color sync with the status color and would like to match logic against expected types.' },
                ],
            };
        },
        methods: {
            grabCode(code){
                this.snackbarItem = code;
                window.getSelection().empty();
                var range = document.createRange();
                range.selectNode(document.getElementById(code));
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.success = true;
            }
        },
    }
</script>

<style scoped>

</style>