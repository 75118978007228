var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"hb-primary hb-design-system-border-radius px-4 white--text mb-6"},[_c('hb-header',{attrs:{"divider":false}},[_c('hb-page-header',{attrs:{"title":"Statuses"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',[_c('div',[_vm._v("import { HbStatus } from 'hummingbird-aviary';")])])]},proxy:true}])})],1)],1),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Basic Status Example + Code"}},[_c('div',{staticClass:"pa-6"},[_c('HbStatus',{attrs:{"status":"Current"}})],1),_c('hb-card',{staticClass:"pa-6",attrs:{"color":"#E0F5F5","no-title":""}},[_c('pre',[_vm._v("<HbStatus status=\"Current\" />\n")])])],1),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Tenant Statuses"}},[_c('hb-data-table',{staticClass:"pa-6",attrs:{"headers":_vm.headers,"items":[
                    { color: '#02AD0F', name: 'Current', description: 'Tenant in good standing'},
                    { color: '#FFD600', name: 'Pending', description: 'Active Lead has paid but not signed the Agreement'},
                    { color: '#FFD600', name: 'Delinquent', description: 'Tenant that is 1+ Days delinquent'},
                    { color: '#FFD600', name: 'Suspended', description: 'Rent, Fee or Auction/Lien Process Suspension, Pending Verified Move-Out'},
                    { color: '#FB4C4C', name: 'Active Lien', description: 'Tenant has been sent an Pre-Lien or Lien Letter'},
                    { color: '#FB4C4C', name: 'Bankruptcy', description: 'Tenant has filed for Bankruptcy (not a status used currently on HB)'},
                    { color: '#FB4C4C', name: 'Auction', description: 'Tenant Scheduled in Auction provides an Auction date'},
                    { color: '#919EAB', name: 'Lease Closed', description: 'Tenant whose space has been moved out would display a Lease Closed status'},
                ]},scopedSlots:_vm._u([{key:"item.chip",fn:function({ item }){return [_c('HbStatus',{attrs:{"status":item.name}})]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.code",fn:function({ item }){return [_c('hb-btn',{attrs:{"color":"primary","small":true},on:{"click":function($event){return _vm.grabCode(item.name)}}},[_vm._v("Grab Code")]),_c('div',{staticStyle:{"position":"absolute","z-index":"-999999","top":"0","left":"0"},attrs:{"id":item.name}},[_vm._v("<HbStatus status=\""+_vm._s(item.name)+"\" />")])]}}])})],1),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Space Statuses"}},[_c('hb-data-table',{staticClass:"pa-6",attrs:{"headers":_vm.headers,"items":[
                    { color: '#02AD0F', name: 'Available', description: 'In Inventory, ready to lease'},
                    { color: '#02AD0F', name: 'Current', description: 'Space is in inventory and ready to be leased'},
                    { color: '#FFD600', name: 'On-Hold', description: 'When the space is rented from Mariposa, the space goes into an On-Hold status for 15 mins'},
                    { color: '#FFD600', name: 'Reserved', description: 'The space is tied to an active lead and is removed from available inventory'},
                    { color: '#FFD600', name: 'Pending', description: 'When a move-in from Hummingbird is discontinued and the move-in is saved to be proceeded at a later time, the space for which the move-in was discontinued would display Pending status'},
                    { color: '#FFD600', name: 'Future Leased', description: 'When a space is rented for a future move-in date, it would display a status Future Leased'},
                    { color: '#919EAB', name: 'Offline', description: 'Space is scheduled for maintenance'},
                    { color: '#919EAB', name: 'Deactivated', description: 'When a space is deactivated through Space Management'},
                    { color: '#919EAB', name: 'Deleted', description: 'When a space is deleted through Space Management'},
                    { color: '#FB4C4C', name: 'Remove Overlock', description: 'Previously delinquent Tenant is now Current requiring either a manual or electronic removal of restrictions'},
                    { color: '#FB4C4C', name: 'To Overlock', description: 'There is an active task for the manager to place an overlock on a space'},
                    { color: '#FB4C4C', name: 'Overlocked', description: 'Space has manually or electronically restricted tenant access'},
                    { color: '#FB4C4C', name: 'Schedule for Auction', description: 'Space is ready to be scheduled for auction'},
                    { color: '#3D8FFF', name: 'Charity', description: 'Space donated for charitable purpose'},
                    { color: '#3D8FFF', name: 'Company', description: 'Space occupied by the company for business purposes'},
                    { color: '#3D8FFF', name: 'Leased', description: 'Space is occupied by a tenant'},
                ]},scopedSlots:_vm._u([{key:"item.chip",fn:function({ item }){return [_c('HbStatus',{attrs:{"status":item.name}})]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.code",fn:function({ item }){return [_c('hb-btn',{attrs:{"color":"primary","small":true},on:{"click":function($event){return _vm.grabCode(item.name)}}},[_vm._v("Grab Code")]),_c('div',{staticStyle:{"position":"absolute","z-index":"-999999","top":"0","left":"0"},attrs:{"id":item.name}},[_vm._v("<HbStatus status=\""+_vm._s(item.name)+"\" />")])]}}])})],1),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Lead Statuses"}},[_c('hb-data-table',{staticClass:"pa-6",attrs:{"headers":_vm.headers,"items":[
                    { color: '#02AD0F', name: 'Converted', description: 'When a lead is converted to a tenant'},
                    { color: '#FFD600', name: 'Active', description: 'Tenant that is digitally or manually nurtured'},
                    { color: '#919EAB', name: 'Retired', description: 'Digitally or manually exhausted'},
                ]},scopedSlots:_vm._u([{key:"item.chip",fn:function({ item }){return [_c('HbStatus',{attrs:{"status":item.name}})]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.code",fn:function({ item }){return [_c('hb-btn',{attrs:{"color":"primary","small":true},on:{"click":function($event){return _vm.grabCode(item.name)}}},[_vm._v("Grab Code")]),_c('div',{staticStyle:{"position":"absolute","z-index":"-999999","top":"0","left":"0"},attrs:{"id":item.name}},[_vm._v("<HbStatus status=\""+_vm._s(item.name)+"\" />")])]}}])})],1),_c('hb-card',{staticClass:"my-6 pb-1",attrs:{"title":"Props"}},[_c('hb-data-table',{staticClass:"pa-6",attrs:{"headers":_vm.propHeaders,"items":_vm.propItems}})],1),_c('hb-card',{staticClass:"my-6 pb-1",attrs:{"title":"Events"}},[_c('hb-data-table',{staticClass:"pa-6",attrs:{"headers":_vm.eventHeaders,"items":_vm.eventItems}})],1),_c('hb-global-notification',{attrs:{"type":"success","description":_vm.snackbarItem + ' <hb-status> component code copied to clipboard.'},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }